button.accordion-button[disabled]::after {
  content: none;  /* Removes the arrow when deactivated */
}

/* Accordion header */
button.accordion-button {
  font-size: 15px; /* keeps size consistent */

  --bs-accordion-active-bg: #{$light};
}

/* Accordion in Upload */
.accordion-collapse.mh-30 {
  max-height: 30vh;
}
