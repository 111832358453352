/* Make extremes rounded */
.splits-bar .plot .barlayer .trace:first-child path {
  clip-path: inset(0% 0% 0% 0% round 41rem 0 0 41rem);
}

.splits-bar .plot .barlayer .trace:last-child path {
  clip-path: inset(0% 0% 0% 0% round 0 41rem 41rem 0);
}

/* Multi-range */
.multi-range {
  display: flex;
  flex-direction: column;

  input[type="range"] {
    pointer-events: none;
    appearance: none;
    border-radius: 14px;

    &::-webkit-slider-thumb {
      pointer-events: all;
      cursor: pointer;
      appearance: none;
      height: 30px;
      width: 5px;
      background-color: map-get($splitsbar-colors, splitsbar-2);

      &:hover {
        background-color: map-get($splitsbar-colors, splitsbar-slider-hover-1);
      }
    }
  }

  .front-range {
    height: 0;
    margin-top: 2px;
    z-index: 2;

    &::-webkit-slider-thumb {
      transform: translate(0px, 10px);
      background-color: map-get($splitsbar-colors, splitsbar-1) !important;

      &:hover {
        background-color: map-get($splitsbar-colors, splitsbar-slider-hover-2) !important;
      }
    }
  }

  .back-range {
    height: 20px;
    z-index: 1;
  }

  .split-range {
    height: 20px;
  }
}

/* Form colors */
.split-fields {
  .train-split span:last-of-type {
    background: map-get($splitsbar-colors, splitsbar-1);
  }

  .validation-split span:last-of-type {
    background: map-get($splitsbar-colors, splitsbar-2);
  }

  .test-split span:last-of-type {
    background: map-get($splitsbar-colors, splitsbar-3);
  }
}
