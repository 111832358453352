// Colors
$custom-red: rgb(239 0 0);
$custom-stage-red: linear-gradient(to right, #e50000 0%, #a30000 100%);
$custom-black: rgb(17 17 17);
$custom-white: rgb(255 255 255);
$custom-blue: rgb(65 143 200);
$custom-green: rgb(0 164 118);
$custom-orange: rgb(247 175 76);
$custom-grey-light: rgb(229 233 236);
$custom-grey-dark: #8c99ac;
$custom-grey-dark-darken: darken($custom-grey-dark, 15%) !default;
$custom-grey-lighter: rgb(248 249 250);
