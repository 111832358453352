/* Custom CSS to position the buttons in the top-right corner */
.card-button-group-tr {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;

  a {
    text-decoration: none;
  }
}

/* Custom CSS for round icon buttons */
.round-icon-btn {
  border-radius: 50%;
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;

  a {
    height: 100%;
    width: 100%;
    display: flex;
    text-decoration: none;
    justify-content: center;
  }

  i {
    display: flex;
    align-items: center;
  }
}

/* Custom CSS for the plus button card */
.plus-card {
  border: 1px solid $card-border-color;
  background-color: $card-cap-bg;
  border-radius: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;
}

.plus-card:hover {
  border: 1px solid $custom-grey-dark;
  background-color: $custom-grey-light;
}

.plus-icon {
  color: $custom-grey-dark-darken;
}

/* Alter cursor on disabled buttons */
span:has(> .btn:disabled) {
  cursor: not-allowed;
}
