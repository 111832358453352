// Button loading indicator for forms -> button must be general sibling of form
form.htmx-request ~ .btn {
  cursor: wait;
  opacity: 0.8;
}

form.htmx-request ~ .btn span.spinner-border {
  display: inline-flex !important;
}

// General Button loading indicator
button.btn.htmx-request {
  cursor: wait;
  opacity: 0.8;
}

button.btn.htmx-request span.spinner-border {
  display: inline-flex !important;
}

// main content loading indicator
#main-content.htmx-request {
  display: none;
}

.main-content-loading-indicator {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1000;
  max-width: 80px;
  max-height: 80px;
  padding: 0;
}

.main-content-loading-indicator > div.spinner-border {
  width: 80px;
  height: 80px;
}

#main-content.htmx-request ~ div.main-content-loading-indicator {
  display: flex !important;
}

// table loading indicator
.table .table-loading-indicator-container {
  padding: 0;
  border: none;
  overflow: hidden;
}

.table-loading-indicator {
  width: 100%;
  height: 2px;
  background-color: $iconbar-active-color;
  animation: slide-in 2s ease-in-out infinite;
  opacity: 0;
}

.htmx-request.table-loading-indicator {
  opacity: 1;
}

.htmx-request .table-loading-indicator {
  opacity: 1;
}

@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}

// hx load elements
[hx-trigger="load"].htmx-request {
  /* Mimic Bootstrap's spinner-border class */
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
  color: $primary;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

// global spinner used e.g. for modal loading indicator
.global-spinner {
  display: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.global-spinner > div.spinner-border {
  width: 80px;
  height: 80px;
}

.htmx-request.global-spinner {
  display: block;
}

.img-container {
  display: flex;
  justify-content: center; /* Horizontally centers the images within each container */
  align-items: center; /* Vertically centers the images within each container */
}

// img loading indicators
.img-container img {
  display: none; /* Hide images by default */
}

.img-placeholder {
  background-color: #f3f3f3; /* Light grey background */
  // TODO: local hosted indicator
  background-image: url("https://htmx.org/img/bars.svg");
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
}
