.csv-example {
  border: 1px solid #404040;
  border-width: 1px 0 0 1px;
  font-size: 10pt;
  font-family: arial, serif;
  border-spacing: 0;
  border-collapse: collapse;

  th {
    background-color: #d4d0c8;
    border: 1px solid #808080;
    border-width: 0 1px 1px 0;
    font-weight: normal;
  }

  td {
    background-color: white;
    padding: 0 4px 0 2px;
    border: 1px solid #c0c0c0;
    border-width: 0 1px 1px 0;

    .heading {
      background-color: #d4d0c8;
      border: 1px solid #808080;
      border-width: 0 1px 1px 0;
      text-align: center;
      padding: 0;
    }
  }
}
