/* Fixed explorer height */
.explorer-size {
  height: 76vh;
}

/* Limit row size */
.row-size {
  height: 12vh !important;
}

/* Limit image detail height */
.mh-80 {
  max-height: 80%;
}

/* Empty row with non-zero height */
.empty-row {
  height: 12vh;
}

/* Highlight element on hover */
.hl-content:hover {
  background-color: lightgrey;
}

/* Action Menu only visible if cursor is in image block */
.hl-content:hover .contextual-btn {
  display: inline-block !important;
}

/* Custom Tooltip */
.fe-tooltip .tooltip-inner {
  border-radius: 0;
  background-color: transparent;
}

/* Custom form overlays based on modals */
.fe-form-overlay-title {
  @extend .p-1;
}

.fe-form-overlay-title h5 {
  @extend .m-0;
}

.fe-form-overlay-body {
  @extend .p-3;

  background-color: $gray-100;
}

.fe-form-overlay-footer {
  @extend .p-0;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;

  button {
    @extend .m-1;
  }
}

.round-icon-btn-sm {
  @extend .round-icon-btn;

  width: 20px;
  height: 26px;
  border-radius: 50%;

  i {
    font-size: 19px;
  }
}

.fe-img-placeholder {
  @extend .img-placeholder;

  background-color: transparent;
  width: unset;
  height: unset;
}

.fe-hl-count:hover span.fe-filter-count {
  @extend .text-secondary-emphasis;
}

.modal-content:has(.headless-modal) {
  .modal-header {
    display: none;
  }

  .modal-body {
    @extend .rounded-top;
  }
}

/* Checkbox highlighting */
.contextual-btn.fe-checkbox:has(input:checked) {
  display: inline-block !important;
}

.hl-content:has(input.fe-check:checked) {
  @extend .bg-info-subtle;
}

/* Stacked image Preview */
.preview-row-size {
  height: 14vh !important;
}

/* Selection information */
.fe-selection-info {
  @extend .bg-info-subtle;
  @extend .border;
  @extend .border-primary;
  @extend .px-2;
}
