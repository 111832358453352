.scrollable-container {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

/* Styling for the images within the container */
.scrollable-container img {
  max-width: 100px; /* Set the desired max width for your images */
  height: auto; /* Maintain aspect ratio */
  margin: 10px; /* Add some spacing between images */
}
