.material-symbols-sharp {
  font-family: "Material Symbols Sharp", serif;
  font-weight: normal;
  font-style: normal;
  font-size: 26px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-variation-settings:
    "FILL" 0,
    "wght" 400,
    "GRAD" 0,
    "opsz" 24;
}

.icon-size-28 {
  font-size: 28px;
  height: 28px;
  width: 28px;
}

.icon-size-48 {
  font-size: 48px;
  height: 48px;
  width: 48px;
}
