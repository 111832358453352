.stars-rating {
  @mixin selected-stars {
    color: gold;

    i {
      font-variation-settings: 'FILL' 1;
    }
  }

  @mixin hover-stars {
    color: gold !important;
    i {
      font-variation-settings: 'FILL' 1;
    }
  }

  input {
    display: none;
  }

  input:checked + label {
    @include selected-stars;
  }

  label:has(~ :checked) {
    @include selected-stars;
  }

  input:hover + label {
    @include hover-stars;
  }

  label:has(~ :hover) {
    @include hover-stars;
  }

  label {
    cursor: pointer;
    color: lightgrey;
    display: unset;
  }
}
