// Color system
$custom-colors: (
  "primary": $custom-blue,
  "secondary": $custom-grey-dark-darken,
  "custom-red": $custom-red,
  "custom-black": $custom-black,
  "custom-white": $custom-white,
  "custom-blue": $custom-blue,
  "custom-green": $custom-green,
  "custom-orange": $custom-orange,
  "custom-grey-light": $custom-grey-light,
  "custom-grey-dark": $custom-grey-dark,
  "custom-grey-dark-darken": $custom-grey-dark-darken,
  "custom-grey-lighter": $custom-grey-lighter,
);
$theme-colors: map-merge($theme-colors, $custom-colors);
$min-contrast-ratio: 3;

// Font
$font-family-sans-serif: "Noto Sans Condensed",
  system-ui, -apple-system, "Segoe UI", roboto,
  "Helvetica Neue", "Noto Sans", "Liberation Sans",
  arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol", "Noto Color Emoji";

// Links
$link-color: $custom-blue;
$link-hover-color: darken($link-color, 15%) !default;

// Components
$border-color: $custom-grey-light !default;

// Cards
$card-border-color: darken($custom-grey-light, 5%);
$card-cap-bg: $custom-grey-lighter;

// Dropdown
$dropdown-link-active-bg: $custom-blue;

// Splits-Bar colors
$splitsbar-colors: (
  splitsbar-1: rgb(65 144 200),
  splitsbar-2: rgb(126 180 217),
  splitsbar-3: rgb(183 216 235),
  splitsbar-slider-hover-1: rgb(106 160 197),
  splitsbar-slider-hover-2: rgb(45 124 180),
);

$theme-colors: map-merge($theme-colors, $splitsbar-colors);
