.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100%;
}

.carousel-control-next,
.carousel-control-prev {
  background-color: var(--bs-primary);
  width: 30px;
  opacity: 0.4;
}

.carousel-control-prev {
  @extend .rounded-start-2;

  margin-left: -2rem;
}

.carousel-control-next {
  @extend .rounded-end-2;

  margin-right: -2rem;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  opacity: 1 !important;
}

.carousel-control-prev:focus,
.carousel-control-next:focus {
  opacity: 0.4;
}

.carousel-indicators {
  bottom: -40px;
}

.carousel-indicators > button {
  background-color: var(--bs-primary);
  opacity: 0.9;
}

.carousel-indicators > button.active {
  opacity: 1;
}

.carousel-img-height {
  height: 19vh; /* when not fixed -> loading indicator dont show up and smaller imgs dont get stretched */
}

[id^="carouselControls_"] {
  margin-left: 2rem;
  margin-right: 2rem;
}

/* Increase contrast in non active buttons */
.carousel-indicators [data-bs-target] {
  opacity: 0.3;
}
