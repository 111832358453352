/* Tooltip container -> results in a black frame */
.tooltip {
  background-color: $custom-black;
  border-radius: 5px;
  padding: 1px;
}

.tooltip .tooltip-arrow {
  display: none;
}

/* Tooltip inner content */
.tooltip .tooltip-inner {
  background-color: $card-cap-bg;
  color: $custom-black;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
}
