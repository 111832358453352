.bs-card-border-radius {
  border-radius: var(--bs-card-border-radius) !important;
}

.gc-image-container {
  position: relative;
  display: inline-block;
}

.top-right {
  margin: 5px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 200;
}

.hidden {
  display: none !important;
}

.not-hidden {
  display: inline !important;
}

.sort-pill-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.pill-icon {
  font-size: 1rem;
}
