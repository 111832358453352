#header {
  // height: 64px;
  .navbar {
    height: 64px;
    padding-top: 0;
    padding-bottom: 0;
    background-color: $custom-red;
    background-image: $custom-stage-red;
    box-shadow: 0 2px 3px rgba(0 0 0 / 20%);
  }

  .navbar:has(+ .nav-top) {
    box-shadow: none;
  }

  .navbar-brand {
    .logo {
      vertical-align: -6px;
    }

    .claim {
      margin-left: 7px;
      vertical-align: -5px;
      font-weight: bold;
      font-size: 19px;
      color: $custom-black;
      font-family: $font-family-sans-serif;

      @include media-breakpoint-only(xs) {
        display: none;
      }
    }
  }

  .nav-link {
    padding: 0.5rem;

    &:hover {
      background-color: rgba($custom-black, 0.2);
    }
  }

  .dropdown-toggle {
    cursor: pointer;

    &::after {
      color: $custom-white;
      border-top: 0.4em solid;
      border-right: 0.4em solid transparent;
      border-bottom: 0;
      border-left: 0.4em solid transparent;
    }

    &:empty::after {
      margin-left: 0.5rem;
    }
  }

  .nav-top {
    background-color: $custom-grey-light;
    box-shadow: 0 2px 3px rgba($custom-black, 0.2);
    width: calc(100% - 55px);
    margin-left: 55px;

    > div {
      height: 42px;
      line-height: 42px;

      > ul > li {
        > a {
          font-weight: 700;
          color: $custom-black;

          &:hover,
          &.active {
            color: $custom-red;
          }
        }
      }
    }
  }
}

.ms-3 {
  text-decoration: none;
}
