#main {
  padding-top: 30px;
  overflow-y: auto;
  padding-bottom: 30px;
}

#main.with-sidebar {
  margin-left: 55px !important;
}

.btn-group a {
  text-decoration: none;
}
