// iconbar colors
$iconbar-text-color: $custom-white;
$iconbar-link-color: $custom-white;
$iconbar-background-color: $custom-grey-dark;
$iconbar-active-color: darken($custom-grey-dark, 8%);
$iconbar-border-color: lighten($custom-grey-dark, 8%);
$iconbar-dropdown-color: lighten($custom-grey-dark, 8%);
$iconbar-dropdown-border-color: lighten($custom-grey-dark, 16%);

// iconbar small
.iconbar-small {
  #iconbar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1019;
    width: 55px;
    padding-top: 64px;
    background-color: $iconbar-background-color;
    color: $iconbar-text-color;
    display: block;

    a {
      color: $iconbar-link-color;

      &:hover {
        text-decoration: underline;
      }
    }

    .iconbar-toggle {
      .btn {
        display: flex !important;
      }

      &.iconbar-toggle-shadow {
        box-shadow: inset 0 2px 3px rgba(0 0 0 / 20%);
      }
    }

    nav {
      .nav {
        .nav-item {
          width: 100%;
          border-bottom: 1px solid $iconbar-border-color;

          .nav-link {
            padding: 0.9rem;
            font-size: 1.2rem;
            white-space: nowrap;
            overflow: hidden;
            display: flex;
            text-decoration: none;

            &:hover,
            &:active,
            &.active {
              background-color: $iconbar-active-color;
            }

            i {
              min-width: 26px;
            }

            span {
              padding-left: 15px;
            }

            &:hover span {
              text-decoration: underline;
            }
          }
        }

        .dropdown-menu {
          display: none;
        }
      }
    }
  }
}

// iconbar collapsible on click
.iconbar-small.iconbar-bs-collapse {
  #iconbar {
    height: auto !important;
    transition: width 0.1s;

    &.show {
      width: 280px;
    }

    .iconbar-toggle {
      display: block;

      button {
        display: block;
        width: 100%;
        padding: 0.9rem;
        font-size: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-align: left;
        color: $custom-white;

        /* &::before {
            //min-width: 25px;
            //content: '\f0c9';
            @include fa-icon;
        }

        &[aria-expanded='true'] {
            &::before {
                content: '\f00d';
            }
        } */
        /* stylelint-disable no-descending-specificity */
        i {
          min-width: 25px;
        }

        span {
          padding-left: 15px;
        }
      }
    }

    /* stylelint-enable no-descending-specificity */
    nav {
      .nav {
        .nav-item {
          &:first-child {
            border-top: 1px solid $iconbar-border-color;
          }
        }
      }
    }
  }
}

// iconbar collapsible on hover
.iconbar-small.iconbar-custom-collapse {
  #iconbar {
    transition: width 0.3s;

    &:hover {
      width: 280px;
    }
  }
}

// iconbar with bootstrap dropdown
/* stylelint-disable no-descending-specificity */
.iconbar-small.iconbar-has-bootstrap-dropdowns {
  #iconbar {
    nav {
      .nav {
        .nav-item {
          .dropdown-menu {
            margin-left: 55px;
          }

          &.show {
            .nav-link {
              width: 280px;
              background-color: $iconbar-active-color;

              span {
                display: inline;
              }
            }

            .dropdown-menu {
              display: block;
            }
          }
        }
      }
    }
  }
}

// iconbar with custom dropdown menues on hover
.iconbar-small.iconbar-has-custom-dropdowns {
  #iconbar {
    nav {
      .nav {
        .nav-item {
          position: relative;

          .dropdown-menu {
            position: absolute;
            top: 100%;
            left: 0;
            margin-left: 55px;
            z-index: 1;
          }

          /* stylelint-enable no-descending-specificity */

          &:hover {
            .nav-link {
              width: 280px;
              background-color: $iconbar-active-color;
            }

            .dropdown-menu {
              display: block;
            }
          }
        }
      }
    }
  }
}
