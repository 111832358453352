#progress-container {
  width: 100%;
}

#progress-bar {
  width: 0;
  height: 20px;
  background-color: $custom-green;
  transition: width 0.2s;
}

input[type="file"]::file-selector-button {
  margin-right: 5px;
}
